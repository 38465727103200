<template>
  <SimpleForm
    :onSubmit="submit"
    :style="[isModal && { margin: 0, width: '100%' }]"
    @done="isModal ? close() : $router.push('/questions')"
  >
    <p class="span-2 form__title">
      {{ isEdit ? 'Update Question' : 'Add a new Question' }}
    </p>
    <v-card v-if="flagQuestion" class="span-2 mb-3 pa-4">
      <v-card-title>Flag Reason</v-card-title>
      <p class="body-1 px-4">{{ flagQuestion.reason }}</p>
      <div></div>
    </v-card>
    <v-select
      v-model="question.type"
      :items="types"
      :rules="[required('You must select a question type')]"
      class="span-2"
      dense
      filled
      item-text="text"
      item-value="value"
      label="Select Type"
    />
    <div class="span-2 mb-6" style="position: relative">
      <h3 class="mb-2">Question Statement</h3>
      <v-textarea
        v-model="question.statement"
        dense
        :rules="[required('You must write a question statement')]"
        label="Question Statement"
        class="span-2"
        outlined
        no-resize
      />
    </div>

    <answers-picker
      v-model="question.options"
      :is-edit="isEdit"
      :picker-type="question.type"
      :question-percentage="question.percentage"
      :selected="question.answer"
      style="margin-top: -25px; margin-left: 25px"
      @selected="question.answer = $event"
    />

    <v-select
      v-model="question.category"
      :items="categories"
      :loading="loadingCategories"
      :rules="[required('Select a category')]"
      dense
      item-text="name"
      item-value="id"
      label="Select Category"
      outlined
      return-object
      @change="subCategories = question.category.subCategories"
    />

    <v-select
      v-model="question.subCategory"
      :disabled="!question.category"
      :items="subCategories"
      :rules="[required('Select a sub-category')]"
      dense
      item-text="name"
      label="Select Subcategory"
      outlined
      return-object
    />

    <v-divider class="span-2" style="margin-bottom: 20px" />

    <v-select
      v-model="question.trial"
      :items="trials"
      :rules="[required('Select Trial')]"
      dense
      item-text="text"
      label="Trial"
      outlined
    />

    <v-select
      v-model="question.for_passage"
      :items="trials"
      :rules="[required('Select Passage')]"
      dense
      item-text="text"
      label="Passage"
      outlined
    />

    <v-text-field
      v-if="isEdit"
      v-model="totalPlayed"
      class="span-2"
      dense
      label="Total Played"
      outlined
      readonly
    />

    <div class="span-2 mb-6">
      <h3 class="mb-2">Answer Explanation</h3>
      <v-textarea
        v-model="question.explanation"
        dense
        :rules="[required('You must write a answer explanation')]"
        label="Answer Explanation"
        class="span-2"
        outlined
        no-resize
      />
    </div>

    <loading-dialog v-model="loading" message="Loading Question Data" />
    <ErrorDialog v-model="error" :error="errorVal" @value="$router.back()" />
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import AnswersPicker from '../../components/questions/AnswersPicker';

import { required } from '@/utils/validators';
import { CategoryService } from '@/services/category-service';
import { QuestionsService } from '@/services/questions-service';
import { MediaGalleryService } from '@/services/media-gallery-service';
import LoadingDialog from '../../components/LoadingDialog';
import { storage } from '@/plugins/firebase';
import axios from 'axios';
import ErrorDialog from '@/components/ErrorDialog';

async function uploadWithMessage(context, list, message, type) {
  context.changeLoadingMessage(message + ' ...');

  const newList = [];
  for (const item of list) {
    const fileName =
      item.filename + '~' + new Date().getTime() + '' + item.fileExtension;
    let reference = storage.ref(type + '/' + fileName);
    let task = reference.put(item.file);
    await task
      .then(async () => {
        if (type === 'videos') {
          const thumbnail = await generateThumbnail(item);
          let thumbRef = storage.ref(type + '/thumbnails/' + fileName);
          let thumbTask = thumbRef.put(thumbnail);
          await thumbTask
            .then(() => {
              window.console.log('thumbnail posted');
            })
            .catch((e) => window.console.log('uploading image error => ', e));
        }
        newList.push(await storage.ref(type).child(fileName).getDownloadURL());
      })
      .catch((e) => window.console.log('uploading image error => ', e));
    context.changeLoadingMessage(
      message + ': ' + item.filenameWithoutExtension
    );
  }

  return newList;
}

async function generateThumbnail(item) {
  const binaryData = [];
  binaryData.push(item.file);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const video = document.createElement('video');
  video.setAttribute('src', URL.createObjectURL(new Blob(binaryData)));
  video.load();
  let thumbnail = await new Promise((resolve) => {
    video.onloadedmetadata = async () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.cur2rentTime = video.duration / 2;
      await video.play();
      context.drawImage(video, 0, 0);
      video.pause();
      const blob = await new Promise((resolve) => {
        return canvas.toBlob(function (blob) {
          resolve(blob);
        });
      });
      resolve(blob);
    };
  });
  return thumbnail;
}

export default {
  name: 'QuestionForm',
  components: {
    ErrorDialog,
    LoadingDialog,
    AnswersPicker,
    SimpleForm
  },

  computed: {
    uploadedVideos() {
      return (this.question?.videos || []).map((image) => {
        return {
          source: image,
          options: { type: 'local', metadata: { uploaded: true } }
        };
      });
    },
    uploadedPdfs() {
      return (this.question?.pdfs || []).map((image) => {
        return {
          source: image,
          options: { type: 'local', metadata: { uploaded: true } }
        };
      });
    },
    uploadedImages() {
      return (this.question?.images || []).map((image) => {
        return {
          source: image,
          options: { type: 'local', metadata: { uploaded: true } }
        };
      });
    }
  },

  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    initialData: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    displayedPdfIndex: null,
    selectedData: {
      images: [],
      videos: [],
      pdfs: []
    },

    customToolbar: [
      [{ header: [false, 2, 3, 4] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      ['clean']
    ],
    editorOptions: {
      modules: {
        toolbar: false
      }
    },
    explanationHtmlMissing: false,
    statementHtmlMissing: false,
    videosServer: null,
    isEdit: false,

    error: false,
    errorVal: {},

    question: {
      options: [],
      selected: null
    },
    loading: false,
    categories: [],

    subCategories: [],
    service: new QuestionsService(),
    categoriesService: new CategoryService(),
    mediaService: new MediaGalleryService(),
    loadingCategories: false,

    types: [
      {
        text: 'Multiple Choice Question',
        value: 'choices'
      },
      {
        text: 'Boolean (True/False)',
        value: 'bool'
      }
    ],
    trials: [
      {
        text: 'Yes',
        value: 'true'
      },
      {
        text: 'No',
        value: 'false'
      }
    ],

    pdfs: [],
    images: [],
    videos: [],

    pdfsToBeDeleted: [],
    imagesToBeDeleted: [],
    videosToBeDeleted: [],

    flagQuestion: null,
    totalPlayed: 0
  }),

  mounted() {
    this.loadQuestion().then(() => {
      this.loadCategories().then(() => {
        this.setInitialData();
      });
    });
  },

  watch: {
    initialData: {
      handler() {
        this.setInitialData();
      },
      deep: true
    }
  },

  methods: {
    required,

    async setInitialData() {
      if (this.initialData?.question) {
        if (
          this.initialData?.choices &&
          this.initialData?.choices.length === 2
        ) {
          this.question.type = 'bool';
        } else {
          this.question.type = 'choices';
        }
        this.question.options = [...this.initialData?.choices];
        this.question.answer = Number(this.initialData?.correctAnswer);
        this.question.statement = this.initialData?.question;
        this.question.explanation = this.initialData?.explanation;
        this.question.category = this.initialData?.category;
        this.subCategories = this.initialData?.category?.subCategories;
        this.question.subCategory = this.initialData?.subCategory;
        this.question.for_passage = this.initialData?.isPassage
          ? 'true'
          : 'false';
        this.question.trial = this.initialData?.trial ? 'true' : 'false';
      }
    },

    pdfChange(index) {
      this.displayedPdfIndex = index;
    },

    close() {
      this.question = {
        options: [],
        selected: null
      };

      this.selectedData = {
        images: [],
        videos: [],
        pdfs: []
      };
      this.$emit('close');
    },

    async loadCategories() {
      this.loadingCategories = true;
      this.categories = await this.categoriesService.fetchAll();
      for (const item of this.categories) {
        if (this.question.category?.id === item.id) {
          this.subCategories = item.subCategories;
          break;
        }
      }
      this.loadingCategories = false;
    },
    async loadQuestion() {
      try {
        if (this.isModal) return;
        if (!this.$route.query.id) return;

        this.isEdit = true;
        this.loading = true;
        this.question = await this.service.fetchOne(this.$route.query.id);
        this.totalPlayed = (
          await this.service.fetchTotalPlayedCount(this.$route.query.id)
        ).count;
        this.selectedData = {
          images: this.question.images,
          videos: this.question.videos,
          pdfs: this.question.pdfs
        };

        this.question.trial = this.question.trial === true ? 'true' : 'false';
        this.question.for_passage =
          this.question.for_passage === true ? 'true' : 'false';
        if (this.$route.query.flag) {
          this.flagQuestion = (
            await axios.get('/flagged-questions/' + this.$route.query.flag)
          ).data;
        }
        if (!this.question.htmlStatement)
          this.question.htmlStatement =
            '<span style="color: rgb(255, 255, 255);">' +
            this.question.statement +
            '</span>';
        if (!this.question.htmlExplanation)
          this.question.htmlExplanation =
            '<span style="color: rgb(255, 255, 255);">' +
            this.question.explanation +
            '</span>';
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.errorVal = {
          title: 'Question does not exist anymore',
          description: 'This question has been deleted by someone.'
        };
      }
    },

    SetNewSelectedData(data) {
      this.selectedData = data;
      this.question.images = data.images;
      this.question.videos = data.videos;
      this.question.pdfs = data.pdfs;
    },

    async submit(context) {
      this.statementHtmlMissing = false;
      this.explanationHtmlMissing = false;

      if (this.question.type === 'choices') {
        if (this.question.options.length < 4) {
          context.reportError({
            title: 'Invalid Question Data',
            description:
              'Provided Question does not have enough answer choices, A Multiple Choice question must have at least 4 options'
          });
          return false;
        }
      }
      if (this.question.images && this.question.images.length > 0) {
        this.question.images = [
          ...this.question.images,
          ...(await uploadWithMessage(
            context,
            this.images,
            'Uploading Images',
            'images'
          ))
        ];
      } else {
        this.question.images = [
          ...(await uploadWithMessage(
            context,
            this.images,
            'Uploading Images',
            'images'
          ))
        ];
      }
      if (this.question.pdfs && this.question.pdfs.length > 0) {
        this.question.pdfs = [
          ...this.question.pdfs,
          ...(await uploadWithMessage(
            context,
            this.pdfs,
            'Uploading Pdfs',
            'pdfs'
          ))
        ];
      } else {
        this.question.pdfs = [
          ...(await uploadWithMessage(
            context,
            this.pdfs,
            'Uploading Pdfs',
            'pdfs'
          ))
        ];
      }
      if (this.question.videos && this.question.videos.length > 0) {
        this.question.videos = [
          ...this.question.videos,
          ...(await uploadWithMessage(
            context,
            this.videos,
            'Uploading Videos',
            'videos'
          ))
        ];
      } else {
        this.question.videos = [
          ...(await uploadWithMessage(
            context,
            this.videos,
            'Uploading Videos',
            'videos'
          ))
        ];
      }
      this.question.trial = this.question.trial === 'true';
      this.question.for_passage = this.question.for_passage === 'true';

      if (this.isEdit) {
        context.changeLoadingMessage('Updating Question');
        try {
          this.question.category = this.question.category.id;
          this.question.subCategory = this.question.subCategory.id;
          await this.service.update(this.question);
          if (this.$route.query.token) {
            const notification = {
              title: 'Flagged Question Updated',
              description:
                'The question you flagged has been rectified. Thank you for your feedback!',
              isForIOS: true,
              isForAndroid: true,
              channel: this.$route.query.token
            };
            await axios
              .post('/notifications/send-to-user', notification)
              .then(() => {
                return true;
              })
              .catch(() => {
                return false;
              });
          }
          return true;
        } catch (e) {
          return false;
        }
      } else {
        context.changeLoadingMessage('Creating A New Question');
        try {
          this.question.category = {
            id: this.question.category.id,
            name: this.question.category.name
          };
          this.question.subCategory = {
            id: this.question.subCategory.id,
            name: this.question.subCategory.name
          };

          await this.service.create(this.question);
          return true;
        } catch (e) {
          return false;
        }
      }
    }
  }
};
</script>

<style scoped>
.modified-message >>> div {
  text-align: center;
}

p {
  font-weight: bold;
  text-align: left;
}

.file-pickers {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto;
}

.customEditor {
  background: #cccbc9;
  border-radius: 8px !important;
}
</style>

<style>
.ql-toolbar {
  border-bottom: 1px solid #fff !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.ql-container {
  border: none !important;
}

.image {
  width: 240px;
  height: 240px;
  object-fit: cover;
}

.fileIcon {
  border: 1px solid #4396e4;
}
</style>
