import firebase from "firebase/app";
import 'firebase/auth';
import "firebase/firestore";
import "firebase/storage";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWeSPxZestU0azlCsEchQ51z6tBdc03Es",
  authDomain: "nclex-c83dd.firebaseapp.com",
  projectId: "nclex-c83dd",
  storageBucket: "nclex-c83dd.appspot.com",
  messagingSenderId: "995232773974",
  appId: "1:995232773974:web:bd3c8f367f80a76a4888f7",
  measurementId: "G-5VE61FG7D1"
};

firebase.initializeApp(firebaseConfig);

//utils
const db = firebase.firestore();
const storage = firebase.storage();

const firestore = firebase.firestore

//collections references

const users = db.collection('v2_users')

export {
    db, storage, firestore, users
}

