<template>
  <div style="position:relative;min-height: 200px">
    <div v-if="!loading && fetch"
         style="position: absolute; right: 10px; top:10px">
      <v-menu offset-x offset-y left close-on-click :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">
            mdi-filter
          </v-icon>
        </template>

        <v-card class="pa-3">
          <v-form ref="filterPieForm">
            <v-select
                v-model="selectedFilter"
                dense
                outlined
                label="Filter"
                :items="filters"
                hide-details
                class="mb-2"
            />
            <v-text-field v-model="startDate" v-if="selectedFilter === 5" label="Start Date" type="date" outlined dense
                          :rules="[required()]"></v-text-field>
            <v-text-field v-model="endDate" v-if="selectedFilter === 5" label="End Date" type="date" outlined dense
                          :rules="[required()]"></v-text-field>
            <v-btn style="width: 100%;" elevation="0" color="primary" @click="applyFilter">Apply</v-btn>
          </v-form>
        </v-card>
      </v-menu>
    </div>
    <p v-if="noData" style="position: absolute; top: 50%;left: 50%; transform: translateX(-50%) translateY(-50%)">No
      questions played yet!</p>

    <line-chart
        v-if="!loading && !noData && fetch"
        class="py-5"
        :chart-data="data"
        :options="options"
        :height="170"
    />
    <div style="height: 100%" class="d-flex justify-center align-center pa-16" v-else-if="loading || !fetch">
      <v-progress-circular indeterminate/>
    </div>
  </div>
</template>

<script>

import {required} from "@/utils/validators";

import {DashboardService} from "@/services/dashboard-service";
import LineChart from "@/utils/charts/line-chart";
import moment from "moment";

export default {
  name: "LineChartComp",
  components: {LineChart},
  props: {
    fetch: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    loading: false,
    service: new DashboardService(),
    data: {
      labels: ['Endless', 'Timed', 'Reviewed', 'qBank', 'Boolean Blitz'],
      datasets: [{
        label: 'Total Games Played',
        data: [],
        fill: false,
        borderColor: '#f5c187',
        tension: 0.3,
      }]
    },
    options: {
      responsive: true,
      title: {
        display: true,
        text: 'Game Modes Analytics',
        fontSize: 18,
        fontColor: '#6b7280',
      },
    },
    noData: false,
    selectedFilter: 0,
    startDate: null,
    endDate: null,
    filters: [
      {
        text: 'All Time',
        value: 0
      },
      {
        text: 'Today',
        value: 1
      },
      {
        text: 'Yesterday',
        value: 2
      },
      {
        text: 'Last 7 Days',
        value: 3
      },
      {
        text: 'Last 28 Days',
        value: 4
      },
      {
        text: 'Custom',
        value: 5
      },
    ]
  }),
  watch: {
    fetch() {
      this.getData()
    }
  },
  methods: {
    required,
    async getData(data = null) {
      this.loading = true
      this.noData = false
      this.data.datasets[0].data = []
      const modes = await this.service.fetch(3, data)
      this.data.datasets[0].data.push(modes.endless)
      this.data.datasets[0].data.push(modes.timed)
      this.data.datasets[0].data.push(modes.reviewed)
      this.data.datasets[0].data.push(modes.qbank)
      this.data.datasets[0].data.push(modes.boolean_blitz)
      if (modes.endless === 0 && modes.timed === 0 && modes.reviewed === 0 && modes.qbank === 0 && modes.boolean_blitz === 0)
        this.noData = true
      this.data = {...this.data}
      this.loading = false
    },
    applyFilter() {
      let startDate, endDate, validated = true
      switch (this.selectedFilter) {
        case 1:
          endDate = startDate = moment().format('YYYY-MM-DD');
          break;
        case 2:
          endDate = startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
          break
        case 3:
          endDate = moment().format('YYYY-MM-DD');
          startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
          break
        case 4:
          endDate = moment().format('YYYY-MM-DD');
          startDate = moment().subtract(28, 'days').format('YYYY-MM-DD');
          break
        case 5:
          if (this.$refs.filterPieForm.validate()) {
            startDate = this.startDate
            endDate = this.endDate
          } else {
            validated = false
          }
      }
      if (startDate && endDate) {
        const data = {
          from: startDate,
          to: endDate
        }
        this.getData(data)
      } else if (validated) {
        this.getData(null)
      }
    },
  },
}
</script>

<style scoped>

</style>
