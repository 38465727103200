import axios from 'axios';

export class FactsService {
    async fetchAll() {
        return (await axios.get('/facts')).data;
    }

    async fetchOne(id) {
        return (await axios.get('/facts/' + id)).data;
    }

    async create(fact) {
        return (await axios.post('/facts', fact)).data;
    }

    async delete(fact) {
        return (await axios.delete('/facts/' + fact.id)).data;
    }

    async update(fact) {
        return (await axios.patch('/facts', fact)).data;
    }
}
