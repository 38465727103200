<template>
  <v-form v-model="valid">
    <data-table
      :loader="loadData"
      :headers="headers"
      :allow-add="false"
      title="In App Products"
      @done="$router.back()"
    >
      <template #primary-action>
        <v-btn
          :disabled="!valid || !getUser().scopes.includes('iaps:edit')"
          color="primary"
          elevation="0"
          @click="save"
        >
          <v-icon>mdi-disk</v-icon>
          Save
        </v-btn>
        <v-btn
          :disabled="!getUser().scopes.includes('iaps:edit')"
          class="ml-2"
          color="primary"
          elevation="0"
          @click="mobileAppBannerDialoge = true"
          >Mobile App Banner
          <v-icon
            :color="mobileAppBannerColor(mobileAppBanner)"
            class="ml-2"
            x-small
          >
            mdi-circle
          </v-icon>
        </v-btn>
      </template>
      <template #sku="{ item }">
        {{ item.sku }}
      </template>
      <template #price="{ item }">
        <v-text-field
          v-model="item.price"
          :rules="[(v) => (!!v && +v > 0) || 'Price must be provided']"
          dense
          outlined
          suffix="USD"
          type="number"
        />
      </template>
      <template #discount="{ item }">
        <v-text-field
          v-model="item.discountPrice"
          :rules="[
            (v) =>
              checkLess(v, item.price) || 'Discount must be less than Price'
          ]"
          dense
          outlined
          suffix="USD"
          type="number"
        />
      </template>

      <template #discountMode="{ item }">
        <v-checkbox hide-details v-model="item.discountMode" />
      </template>
      <template #action="{ item }">
        <v-icon
          v-if="
            !(item.sku === 'lifetime') &&
            getUser().scopes.includes('iaps-offers:view')
          "
          color="green"
          small
          @click="edit(item)"
          >mdi-pencil
        </v-icon>
      </template>
    </data-table>

    <v-dialog v-model="mobileAppBannerDialoge" width="500">
      <v-card>
        <v-card-title class="mb-2">Mobile App Banner</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="mobileAppBanner.name"
            :rules="[required()]"
            dense
            label="Name"
            outlined
          />
          <v-text-field
            v-model="mobileAppBanner.description"
            :rules="[required()]"
            dense
            label="Description"
            outlined
          />
          <v-text-field
            v-model="mobileAppBanner.percentage_off"
            :rules="[required()]"
            dense
            label="Percentage Off"
            outlined
          />

          <DateTimePicker
            v-model="mobileAppBanner.sale_period"
            :rules="[required()]"
            label="Sale Period"
            timeFormat="hh:mm:ss a"
          >
          </DateTimePicker>

          <v-checkbox
            v-model="mobileAppBanner.is_active"
            :label="'Active'"
            color="primary"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" @click="submitMobileBanner()">Save</v-btn>
          <v-btn color="primary" @click="closeMobileBannerModal"
            >Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="editLoading" message="Updating Products" />
    <loading-dialog v-model="loading" message="Please wait..." />
  </v-form>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import { IapService } from '@/services/iap-service';
import { getUser } from '@/utils/local';
import { required } from '@/utils/validators';
import DateTimePicker from '../../components/settings/DateTimePicker';

const { format, parseISO } = require('date-fns');

export default {
  components: { LoadingDialog, DataTable, DateTimePicker },

  data: () => ({
    editLoading: false,
    editDialog: false,

    valid: false,
    loading: false,
    service: new IapService(),

    items: [],
    headers: [
      {
        text: 'SKU',
        value: 'sku',
        sortable: false
      },
      {
        text: 'Price',
        value: 'price'
      },
      {
        text: 'Discounted Price',
        value: 'discount'
      },
      {
        width: 100,
        text: 'Discount Active',
        value: 'discountMode'
      },
      {
        text: 'Action',
        value: 'action',
        sortable: false
      }
    ],

    // For Sale Banner
    mobileAppBannerDialoge: false,
    mobileAppBanner: {}
  }),
  mounted() {
    this.$axios
      .get('/sales')
      .then((response) => {
        this.setBannersDetails(response);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  methods: {
    required,
    getUser,
    closeMobileBannerModal() {
      this.$axios
      .get('/sales')
      .then((response) => {
        this.setBannersDetails(response);
      })
      .catch((error) => {
        console.error(error);
      });

      this.mobileAppBannerDialoge = false;

    },
    mobileAppBannerColor(mobileAppBanner) {
      if (
        mobileAppBanner &&
        (mobileAppBanner.is_active === true) &
          (mobileAppBanner.sale_period > mobileAppBanner.currentDate)
      )
        return 'yellow';
      return 'red';
    },
    formateDate(inputDate, outputFormat) {
      const dateObj1 = parseISO(inputDate);
      const formattedDate = format(dateObj1, outputFormat);
      return formattedDate;
    },
    setBannersDetails(response) {
      // const mobileBannerIndex = response.data.sales.findIndex(
      //     (sale) => sale.platform === 0
      // );

      // if (mobileBannerIndex !== -1) {
      // const mobileBanner = response.data.sales[mobileBannerIndex];
      const mobileBanner = response.data.sales[0];
      this.mobileAppBanner.id = mobileBanner.id;
      this.mobileAppBanner.name = mobileBanner.name;
      this.mobileAppBanner.description = mobileBanner.description;
      this.mobileAppBanner.percentage_off = mobileBanner.percentage_off;
      this.mobileAppBanner.is_active = mobileBanner.is_active;
      this.mobileAppBanner.createdAt = mobileBanner.createdAt;
      this.mobileAppBanner.sale_period = this.formateDate(
        mobileBanner.sale_period,
        'yyyy-MM-dd hh:mm:ss a'
      );

      this.mobileAppBanner.currentDate = new Date().toISOString();
      this.mobileAppBanner.currentDate = this.formateDate(
        this.mobileAppBanner.currentDate,
        'yyyy-MM-dd hh:mm:ss a'
      );

      console.log('this.mobileAppBanner: ', this.mobileAppBanner);

      //   this.dataTableKey += 1;
      // }
    },
    async submitMobileBanner() {
      this.loading = true;
      const date = new Date(this.mobileAppBanner.sale_period);
      const clonedBanner = {
        ...this.mobileAppBanner,
        sale_period: date.toISOString()
      };

      console.log('this.items: ', this.items);

      // this.items = this.items.map((item) => {
      //         return {
      //           ...item,
      //           discountMode: item.product.sku !== 'lifetime_new' && item.product.listings['en-US'].title !== 'Lifetime' ? clonedBanner.is_active : item.discountMode,
      //           discountPrice: item.product.sku !== 'lifetime_new' && item.product.listings['en-US'].title !== 'Lifetime'
      //               ? item.price - (item.price * clonedBanner.percentage_off) / 100 : item.discountPrice
      //         };
      //     });

      this.items = this.items.map((item) => {
        return {
          ...item,
          discountMode:
            item.sku !== 'lifetime_new'
              ? clonedBanner.is_active
              : item.discountMode,
          discountPrice:
            item.sku !== 'lifetime_new'
              ? item.price - (item.price * clonedBanner.percentage_off) / 100
              : item.discountPrice
        };
      });

      console.log('clonedBanner: ', clonedBanner);

      try {
        await this.$axios.patch('/sales', clonedBanner);

        this.$toast.success(
          'Sales Banner and Discount except Lifetime updated successfully!',
          {
            position: 'bottom-left',
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: 'button',
            icon: true,
            rtl: false
          }
        );
      } catch (error) {
        this.toast('Error occurred while updating Sales Banner');
      }

      // let shouldReturn = true;
      // this.mobileAppBannerDialoge = false;
      // this.loading = false;
      // if (shouldReturn) return;

      console.log('this.items after sales patches: ', this.items);

      try {
        for (const item of this.items) {
          if (!item.offers) {
            item.offers = [];
          }

          await this.service.update(item);
        }

        this.closeMobileBannerModal();

        this.$toast.success('Products updated successfully', {
          position: 'bottom-left',
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: 'button',
          icon: true,
          rtl: false
        });
      } catch (error) {
        this.toast('Error occurred while updating products');
      }

      this.loading = false;

      this.$axios
        .get('/sales')
        .then((response) => {
          this.setBannersDetails(response);
        })
        .catch((error) => {
          console.error(error);
        });

      document.getElementById('refresh').click();
    },

    checkLess(discount, price) {
      //eslint-disable-next-line no-extra-boolean-cast
      if (!!discount) {
        //eslint-disable-next-line no-extra-boolean-cast
        return parseInt(discount) < parseInt(!!price ? price : '0');
      } else {
        return true;
      }
    },
    edit(item) {
      this.$router.push(`/iaps/offers?id=${item.sku}`);
    },
    async save() {
      for (const item of this.items) {
        if (item.sku === 'lifetime_new' || item.sku === 'lifetime_v1') {
          item.price = +item.price;

          if (item.sku === 'lifetime_new') {
            item.discountPrice = +item.discountPrice;
          }

          if (!item.offers) {
            item.offers = [];
          }

          await this.service.update(item);
        }
      }

      this.$toast.success('Life time Products updated successfully', {
        position: 'bottom-left',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: 'button',
        icon: true,
        rtl: false
      });

      if (this.mobileAppBanner && this.mobileAppBanner.is_active === false) {
        this.editLoading = true;
        for (const item of this.items) {
          item.price = +item.price;

          item.discountPrice = +item.discountPrice;

          if (!item.offers) {
            item.offers = [];
          }

          await this.service.update(item);
        }

        this.editLoading = false;
      } else {
        this.$toast.error(
          'To edit the discount price of every package manually except lifetime package, kindly deactivate the Banner sales first.',

          {
            position: 'bottom-left',
            timeout: 10000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: 'button',
            icon: true,
            rtl: false
          }
        );
      }

     document.getElementById('refresh').click();
    },
    // async save() {
    //   this.editLoading = true;
    //   for (const item of this.items) {
    //     item.price = +item.price;
    //     item.discountPrice = +item.discountPrice;

    //     if (!item.offers) {
    //       item.offers = [];
    //     }

    //     await this.service.update(item);
    //   }
    //   this.editLoading = false;
    // },
    async loadData() {
      this.items = Object.values(await this.service.fetchAll());
      console.log('items in loadData: ', this.items);

      this.items = this.items.map((item) => {
        return {
          ...item,
          discountPrice: Math.floor(item.discountPrice * 100) / 100
        };
      });

      this.items.sort((a, b) => {
        if (a.sku.includes('lifetime_new')) {
          return -1;
        } else if (b.sku.includes('lifetime_new')) {
          return 1;
        } else if (a.sku.includes('standard_student')) {
          return -1;
        } else if (b.sku.includes('standard_student')) {
          return 1;
        } else if (a.sku.includes('premium_prepper')) {
          return -1;
        } else if (b.sku.includes('premium_prepper')) {
          return 1;
        } else if (a.sku.includes('v2')) {
          return -1;
        } else if (b.sku.includes('v2')) {
          return 1;
        } else {
          return 0;
        }
      });

      return this.items;
    }
  }
};
</script>

<style scoped></style>
