<template>
  <data-table
      :loader="loadData"
      :headers="headers"
      title="Flagged Questions"
      @done="$router.back()"
      :allow-add="false"
      :delete-handler="getUser() ? getUser().scopes.includes('flagged-questions:delete') ?  deleteItem : null : null"
      :edit-handler="getUser() ? getUser().scopes.includes('flagged-questions:edit') ?  editQuestion : null : null"
  >
    <!--    <template #extra-actions="{item}">-->
    <!--      <v-icon @click="changeValidStatus(true,item)" small color="green">mdi-check</v-icon>-->
    <!--      <v-icon @click="changeValidStatus(false,item)" small color="red">mdi-close</v-icon>-->
    <!--    </template>-->
    <template v-slot:username="{ item }">
      {{ item.user_data.name }}
    </template>
    <template v-slot:email="{ item }">
      {{ item.user_data.email }}
    </template>
    <template v-slot:reason="{ item }">
      {{ item.reason.split('_-_')[0] }}
    </template>
    <template v-slot:explanation="{ item }">
      {{ item.reason.split('_-_')[1] }}
    </template>
    <template v-slot:is_valid="{ item }">
      <v-checkbox v-model="item.is_valid" readonly></v-checkbox>
    </template>
    <template #timestamp="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
  </data-table>
</template>

<script>
import DataTable from '../../components/DataTable';
import axios from 'axios';
import {getUser} from '@/utils/local';
import dayjs from "dayjs";


export default {
  components: {DataTable},

  data: () => ({
    loading: false,

    headers: [
      {
        text: 'User Name',
        value: 'username',
      },
      {
        text: 'User Email',
        value: 'email',
      },
      {
        text: 'Reason',
        value: 'reason',
      },
      {
        text: 'Explanation',
        value: 'explanation',
      },
      {
        text: 'Question',
        value: 'question_id',
        sortable: false
      },
      {
        width: 180,
        text: 'Timestamp',
        value: 'timestamp'
      },
      // {
      //   width: 50,
      //   text: 'Valid',
      //   value: 'is_valid',
      //   sortable: false
      // }
    ]
  }),

  methods: {
    getUser,
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY - hh:mm a');
    },
    async editQuestion(item) {
      if (item.user.token) {
        await this.$router.push(`/question?id=${item.question_id}&token=${item.user.token}&flag=${item.id}`)
      } else {
        await this.$router.push(`/question?id=${item.question_id}&flag=${item.id}`)
      }
    },
    async deleteItem(item) {
      return await axios.delete(`/flagged-questions/${item.id}`);
    },
    async loadData() {
      return (await axios.get('/flagged-questions')).data;
    },
    async changeValidStatus(event, item) {
      if (event) {
        if (confirm('Are you sure')) {
          try {
            this.loading = true
            item.is_valid = true
            console.log(event)
            console.log(item)
            await axios.patch(`/flagged-questions/${item.id}`, item)
          } catch (e) {
            console.log(e)
          }
          this.loading = false
        }
      } else {
        let reason
        while (!reason && reason !== null) {
          reason = prompt('Reason')
        }
        if (reason) {
          try {
            this.loading = true
            item.is_valid = false
            item.invalid_reason = reason
            await axios.patch(`/flagged-questions/${item.id}`, item)
          } catch (e) {
            console.log(e)
          }
          this.loading = false
        }
      }
    }
  }
};
</script>
