var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Notifications","allow-add":_vm.getUser() ? _vm.getUser().scopes.includes('notifications:new') : false,"delete-handler":_vm.getUser() ? _vm.getUser().scopes.includes('notifications:delete') ? _vm.service.delete : null : null,"edit-handler":_vm.getUser() ? _vm.getUser().scopes.includes('notifications:edit') ? _vm.edit : null : null},on:{"add-new":_vm.addNew,"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"extra-actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.sendNotification(item)}}},[_vm._v("mdi-send")])]}},{key:"channel",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"chip"},[_vm._v(_vm._s(item.channel))])]}},{key:"isForIOS",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":"","readonly":""},model:{value:(item.isForIOS),callback:function ($$v) {_vm.$set(item, "isForIOS", $$v)},expression:"item.isForIOS"}})]}},{key:"isForAndroid",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":"","readonly":""},model:{value:(item.isForAndroid),callback:function ($$v) {_vm.$set(item, "isForAndroid", $$v)},expression:"item.isForAndroid"}})]}}])}),_c('loading-dialog',{attrs:{"message":"Sending Notification"},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }