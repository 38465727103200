<template>
  <div style="position:relative;min-height: 200px">
    <div v-if="!loading && fetch"
         style="position: absolute; right: 10px; top:10px">
      <v-menu offset-x offset-y left close-on-click :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">
            mdi-filter
          </v-icon>
        </template>

        <v-card class="pa-3">
          <v-form ref="filterPieForm">
            <v-select
                v-model="selectedFilter"
                dense
                outlined
                label="Filter"
                :items="filters"
                hide-details
                class="mb-2"
            />
            <v-text-field v-model="startDate" v-if="selectedFilter === 5" label="Start Date" type="date" outlined dense
                          :rules="[required()]"></v-text-field>
            <v-text-field v-model="endDate" v-if="selectedFilter === 5" label="End Date" type="date" outlined dense
                          :rules="[required()]"></v-text-field>
            <v-btn style="width: 100%;" elevation="0" color="primary" @click="applyFilter">Apply</v-btn>
          </v-form>
        </v-card>
      </v-menu>
    </div>
    <p v-if="noData" style="position: absolute; top: 50%;left: 50%; transform: translateX(-50%) translateY(-50%)">No
      questions played yet!</p>
    <pieChart
        v-if="!loading && !noData && fetch"
        class="py-5"
        :chart-data="data"
        :options="options"
        :height="230"
    />
    <div style="height: 100%" class="d-flex justify-center align-center pa-16" v-else-if="loading || !fetch">
      <v-progress-circular indeterminate/>
    </div>
  </div>
</template>

<script>
import PieChart from '@/utils/charts/pie-chart'
import {DashboardService} from "@/services/dashboard-service";
import {required} from "@/utils/validators";
import moment from "moment";

export default {
  name: "PieChartComp",
  components: {PieChart},
  props: {
    fetch: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    loading: false,
    service: new DashboardService(),
    data: {
      labels: ['Correct', 'Incorrect'],
      datasets: [
        {
          backgroundColor: ['#80d7b5', '#ee8782'],
          data: [],
        },
      ],
    },
    options: {
      responsive: true,
      title: {
        display: true,
        text: 'Questions Analytics',
        fontSize: 22,
        fontColor: '#6b7280',
      },
    },
    noData: false,
    selectedFilter: 0,
    startDate: null,
    endDate: null,
    filters: [
      {
        text: 'All Time',
        value: 0
      },
      {
        text: 'Today',
        value: 1
      },
      {
        text: 'Yesterday',
        value: 2
      },
      {
        text: 'Last 7 Days',
        value: 3
      },
      {
        text: 'Last 28 Days',
        value: 4
      },
      {
        text: 'Custom',
        value: 5
      },
    ]
  }),
  watch: {
    fetch() {
      this.getData()
    }
  },
  methods: {
    required,
    async getData(data = null) {
      this.loading = true
      this.noData = false
      this.data.datasets[0].data = []
      const questionAnalytics = await this.service.fetch(1, data)
      this.data.datasets[0].data.push(questionAnalytics.correct)
      this.data.datasets[0].data.push(questionAnalytics.incorrect)
      if (questionAnalytics.correct === 0 && questionAnalytics.incorrect === 0)
        this.noData = true
      this.data = {...this.data}
      this.loading = false
      this.$emit('completed', true)
    },
    applyFilter() {
      let startDate, endDate, validated = true
      switch (this.selectedFilter) {
        case 1:
          endDate = startDate = moment().format('YYYY-MM-DD');
          break;
        case 2:
          endDate = startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
          break
        case 3:
          endDate = moment().format('YYYY-MM-DD');
          startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
          break
        case 4:
          endDate = moment().format('YYYY-MM-DD');
          startDate = moment().subtract(28, 'days').format('YYYY-MM-DD');
          break
        case 5:
          console.log('custom')
          if (this.$refs.filterPieForm.validate()) {
            startDate = this.startDate
            endDate = this.endDate
          } else {
            validated = false
          }
      }
      if (startDate && endDate) {
        const data = {
          from: startDate,
          to: endDate
        }
        this.getData(data)
      } else if (validated) {
        this.getData(null)
      }
    },
  },
}
</script>

<style scoped>

</style>
