<template>
  <div style="position:relative;min-height: 200px">
    <div v-if="!loading && type === 0 && fetch"
         style="position: absolute; right: 10px; top:10px">
      <div class="d-flex justify-center align-center">
        <v-icon @click="toggleWeek(false)">mdi-chevron-left</v-icon>
        <p class="ma-0">{{ formatDate(startDate, 'MMM D') }} - {{ formatDate(endDate, 'MMM D') }}</p>
        <v-icon :disabled="startDate && startDate.week() === currentDate" @click="toggleWeek(true)">mdi-chevron-right
        </v-icon>
      </div>
    </div>
    <div v-if="!loading && type === 1 && fetch"
         style="position: absolute; right: 10px; top:10px">
      <div class="d-flex justify-center align-center">
        <v-icon @click="toggleYear(false)">mdi-chevron-left</v-icon>
        <p class="ma-0">{{ year }}</p>
        <v-icon :disabled="year === currentYear" @click="toggleYear(true)">mdi-chevron-right</v-icon>
      </div>
    </div>
    <div v-if="!loading && fetch"
         style="position: absolute; left: 10px; top:10px">
      <v-btn elevation="0" color="primary" @click="toggleType">{{ type === 0 ? 'Weekly' : 'Monthly' }}</v-btn>
    </div>
    <p v-if="noData" style="position: absolute; top: 50%;left: 50%; transform: translateX(-50%) translateY(-50%)">No
      questions played yet!</p>

    <bar-chart
        v-if="!loading && type === 0 && fetch"
        class="py-5"
        :chart-data="dataWeek"
        :options="optionsWeek"
        :height="170"
    />
    <bar-chart
        v-if="!loading  && type === 1 && fetch"
        class="py-5"
        :chart-data="dataMonth"
        :options="optionsMonth"
        :height="170"
    />
    <div style="height: 100%" class="d-flex justify-center align-center pa-16" v-else-if="loading || !fetch">
      <v-progress-circular indeterminate/>
    </div>
  </div>
</template>

<script>
import {DashboardService} from "@/services/dashboard-service";
import BarChart from "@/utils/charts/bar-chart";
import moment from "moment";

export default {
  name: "BarChartUsers",
  components: {BarChart},
  props: {
    fetch: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    loading: false,
    type: 0,
    service: new DashboardService(),
    dataWeek: {
      labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      datasets: [{
        label: 'Weekly Active Users',
        backgroundColor: '#495db6',
        data: [],
        fill: true,
        borderWidth: 1
      }]
    },
    dataMonth: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [{
        label: 'Monthly Active Users',
        backgroundColor: '#495db6',
        data: [],
        fill: true,
        borderWidth: 1
      }]
    },
    optionsWeek: {
      responsive: true,
      title: {
        display: true,
        text: 'Weekly User Analytics',
        fontSize: 18,
        fontColor: '#6b7280',
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    },
    optionsMonth: {
      responsive: true,
      title: {
        display: true,
        text: 'Monthly User Analytics',
        fontSize: 18,
        fontColor: '#6b7280',
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    },
    noData: false,
    startDate: null,
    endDate: null,
    year: moment().year(),
  }),
  computed: {
    currentDate() {
      return moment().week()
    },
    currentYear() {
      return moment().year()
    }
  },
  watch: {
    fetch() {
      const date = moment()
      this.getDates(date)
      this.applyFilterWeek()
    }
  },
  methods: {
    async getDataWeek(data = null) {
      this.loading = true
      this.dataWeek.datasets[0].data = []
      const weekGames = await this.service.fetch(4, data)
      this.dataWeek.datasets[0].data.push(weekGames.sunday)
      this.dataWeek.datasets[0].data.push(weekGames.monday)
      this.dataWeek.datasets[0].data.push(weekGames.tuesday)
      this.dataWeek.datasets[0].data.push(weekGames.wednesday)
      this.dataWeek.datasets[0].data.push(weekGames.thursday)
      this.dataWeek.datasets[0].data.push(weekGames.friday)
      this.dataWeek.datasets[0].data.push(weekGames.saturday)
      this.dataWeek = {...this.dataWeek}
      this.loading = false
      this.$emit('completed', true)
    },
    async getDataMonth(data = null) {
      this.loading = true
      this.dataMonth.datasets[0].data = []
      const monthGames = await this.service.fetch(4, data)
      this.dataMonth.datasets[0].data.push(monthGames.jan)
      this.dataMonth.datasets[0].data.push(monthGames.feb)
      this.dataMonth.datasets[0].data.push(monthGames.mar)
      this.dataMonth.datasets[0].data.push(monthGames.apr)
      this.dataMonth.datasets[0].data.push(monthGames.may)
      this.dataMonth.datasets[0].data.push(monthGames.jun)
      this.dataMonth.datasets[0].data.push(monthGames.jul)
      this.dataMonth.datasets[0].data.push(monthGames.aug)
      this.dataMonth.datasets[0].data.push(monthGames.sep)
      this.dataMonth.datasets[0].data.push(monthGames.oct)
      this.dataMonth.datasets[0].data.push(monthGames.nov)
      this.dataMonth.datasets[0].data.push(monthGames.dec)
      this.dataMonth = {...this.dataMonth}
        this.loading = false
    },
    toggleType() {
      console.log(this.type)
      if (this.type === 0) {
        this.type = 1
        this.applyFilterMonth()
      } else {
        this.type = 0
        this.applyFilterWeek()
      }
    },
    toggleWeek(next) {
      if (next) {
        const date = moment(this.startDate).add(1, 'week')
        this.getDates(date)
      } else {
        const date = moment(this.startDate).subtract(1, 'week')
        this.getDates(date)
      }
      this.applyFilterWeek()
    },
    getDates(date) {
      this.startDate = moment(date).startOf('week');
      this.endDate = moment(date).endOf('week');
    },
    formatDate(date, format = 'yyyy-mm-dd') {
      return moment(date).format(format)
    },
    applyFilterWeek() {
      const data = {
        from: moment(this.startDate).format('YYYY-MM-DD'),
        to: moment(this.endDate).format('YYYY-MM-DD')
      }
      this.getDataWeek(data)
    },
    applyFilterMonth() {
      const data = {
        year: this.year
      }
      this.getDataMonth(data)
    },
    toggleYear(next) {
      if (next)
        this.year++
      else
        this.year--

      this.applyFilterMonth()
    }
  },
}
</script>

<style scoped>

</style>
