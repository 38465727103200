<template>
  <div>
    <data-table
        :loader="loadData"
        :headers="headers"
        title="Passage Questions"
        :allow-add="getUser() ? getUser().scopes.includes('passage-questions:new') : false"
        @add-new="addNew"
        @done="$router.back()"
        :delete-handler="getUser() ? getUser().scopes.includes('passage-questions:delete') ? service.delete : null : null"
        :edit-handler="getUser() ? getUser().scopes.includes('passage-questions:edit') ?  edit : null : null"
    >
      <template #primary-action>
        <v-btn v-if="getUser() && getUser().scopes.includes('passage-questions:import')" @click="openUploadQuestions"
               elevation="0" class="mr-3">Import
        </v-btn>
        <v-btn v-if="getUser() && getUser().scopes.includes('passage-questions:export') && items.length > 0 && !loading"
               @click="exportQuestions"
               elevation="0" color="primary" class="mr-3">Export
        </v-btn>
      </template>
      <template v-slot:createdAt="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
      <template v-slot:passage="{ item }">
        {{ item.passage.substring(0, 100) }}
      </template>
      <template #premium="{ item }">
        <v-checkbox dense v-model="item.premium" readonly hide-details/>
      </template>
      <template #active="{ item }">
        <v-checkbox dense v-model="item.active" readonly hide-details/>
      </template>
    </data-table>
    <v-dialog persistent width="50%" v-model="showFileUpload">
      <v-card class="px-5 py-7">
        <div v-if="!isUploading">
          <v-card-title class="px-0">{{
              isUploaded ? 'Questions Imported' : 'Import Passage Questions By CSV'
            }}
          </v-card-title>
          <v-form ref="import">
            <div v-if="isUploaded">
              <div class="d-flex py-5">
                <v-chip class="mr-2" color="primary">Total: {{ this.questions.length }}</v-chip>
                <v-chip class="mr-2" color="success">Completed: {{ this.added }}
                </v-chip>
                <v-chip class="mr-2" color="error">Rejected: {{ this.questions.length - this.added }}</v-chip>
              </div>
              <div class="mb-2">
                <ul style="color: red" v-for="(error,i) of importErrors" :key="i">
                  <li>{{ error.text }} at entry # {{ error.item }}</li>
                </ul>
              </div>
              <div class="d-flex justify-end">
                <v-btn @click="cancelUpload" class="mr-2">OK</v-btn>
                <v-btn @click="newImport" class="ml-2" color="primary">New Import</v-btn>
              </div>
            </div>
            <div v-else>
              <p>Download a <a href="/passage_question_template.csv" download="passage_question_template.csv">sample CSV
                template</a> to
                see an example of the format required. <br> Use Pipe Operator (|) to separate multiple question ids in
                one cell.</p>
              <p>Download <a href="javascript:void(0)" @click="download">Questions</a> reference sheet
                to refer to their ids.</p>
              <v-file-input :rules="[required()]" v-model="uploadFile" outlined dense label="Upload CSV" prepend-icon=""
                            accept=".csv"
                            @change="bulkUpload"/>
              <div class="d-flex justify-end">
                <div>
                  <v-btn @click="cancelUpload" class="mr-2">Cancel</v-btn>
                  <v-btn @click="uploadQuestions" class="ml-2" color="primary">Upload</v-btn>
                </div>
              </div>
            </div>
          </v-form>
        </div>
        <div v-else class="pa-16 d-flex justify-center align-center">
          <v-progress-circular indeterminate class="mr-3"/>
          <p class="ma-0">Importing Questions...</p>
        </div>
      </v-card>
    </v-dialog>
    <loading-dialog v-model="loading" message="Loading..."/>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import {getUser} from '@/utils/local';
import {required} from "@/utils/validators";
import LoadingDialog from "@/components/LoadingDialog";
import moment from "moment";
import {PassageQuestionsService} from "@/services/passage_questions-service";
import {QuestionsService} from "@/services/questions-service";
import Papa from "papaparse";

export default {
  components: {LoadingDialog, DataTable},

  data: () => ({
    loading: false,
    service: new PassageQuestionsService(),
    questionsService: new QuestionsService(),

    headers: [
      {
        text: 'Passage Title',
        value: 'heading',
      },
      {
        text: 'Question Passage',
        value: 'passage',
        sortable: false
      },
      {
        text: 'Created At',
        value: 'createdAt'
      },
      {
        width: 100,
        text: 'Premium',
        value: 'premium'
      },
      {
        width: 120,
        text: 'Active',
        value: 'active'
      }
    ],


    items: [],

    showFileUpload: false,
    allSubs: [],
    uploadFile: null,
    isUploaded: false,
    isUploading: false,
    questions: [],
    importErrors: [],
    added: 0,
  }),

  methods: {
    getUser,
    required,
    addNew() {
      this.$router.push('/passage-question');
    },
    edit(item) {
      this.$router.push(`/passage-question?id=${item.id}`);
    },
    async loadData() {
      this.items = await this.service.fetchAll();
      return this.items
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY - hh:mm A")
    },
    openUploadQuestions() {
      this.showFileUpload = true
    },
    bulkUpload(item) {
      if (item) {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const result = Papa.parse(data.toString(), {
            header: true,
            transformHeader: function (header) {
              return header.toLowerCase()
            },
            skipEmptyLines: true
          })
          this.questions = result.data
        }
        reader.readAsText(item)
      }
    },
    async uploadQuestions() {
      if (this.$refs.import.validate()) {
        this.added = 0
        this.isUploading = true
        this.isUploaded = false
        this.importErrors = []
        console.log(this.questions)
        for (let i = 0; i < this.questions.length; i++) {
          let item = this.questions[i];
          let error = false
          const index = this.questions.indexOf(item);
          const question = {}
          if (item.title) {
            question.heading = item.title
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Question Title is missing'
            })
            error = true
          }
          if (item.passage) {
            question.passage = item.passage
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'Question Passage is missing'
            })
            error = true
          }
          const selectedQuestions = item.questions.split('|').filter((o) => o !== '')
          const questions = []
          if (selectedQuestions && selectedQuestions.length > 0) {
            selectedQuestions.forEach((selectedQuestion) => {
              questions.push(selectedQuestion)
            })
          } else {
            this.importErrors.push({
              item: index + 2,
              text: 'At least one question is required'
            })
            error = true
          }
          question.questions = questions
          question.images = []
          question.active = item.active.toLowerCase() === 'true';
          question.premium = item.premium.toLowerCase() === 'true';
          question.createdAt = new Date()
          console.log(question, 'Question')
          if (!error) {
            await this.service.create(question).then(() => {
              this.added += 1
              return true;
            }).catch((e) => {
              this.importErrors.push({
                item: index + 2,
                text: e
              })
            })
          }
        }
        this.isUploading = false
        this.isUploaded = true
      }
    },


    cancelUpload() {
      if (this.isUploaded) {
        document.getElementById('refresh').click()
      }
      this.uploadFile = null
      this.isUploaded = false
      this.isUploading = false
      this.showFileUpload = false
    },

    newImport() {
      this.questions = []
      this.uploadFile = null
      this.importErrors = []
      this.isUploading = false
      this.isUploaded = false
    },

    exportQuestions() {
      this.loading = true
      const items = [...this.items]
      const csv = this.toCSVQuestions(items)
      const name = 'passage_questions.csv'
      const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false
    },
    toCSVQuestions(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : [...items];
      let str = 'Title,Passage,Premium,Active,Questions\r\n';
      for (let i = 0; i < array.length; i++) {
        const item = {...array[i]}
        let line = '';
        item.heading = '"' + item.heading.replaceAll('"', "'") + '"'
        item.passage = '"' + item.passage.replaceAll('"', "'") + '"'
        line += item.heading + ',' +
            item.passage + ',' + item.premium + ',' + item.active + ',' + item.questions.join('|')
        str += line + '\r\n'
      }

      return str
    },
    async download() {
      this.loading = true
      const questions = await this.questionsService.getPassageQuestions();
      console.log(questions)
      let name, blob;
      const csv = this.toCSV(questions)
      name = 'questions.csv'
      blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.loading = false
    },
    toCSV(items) {
      const array = typeof items != 'object' ? JSON.parse(items) : items;
      let str = 'ID,Statement\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = '';
        array[i].statement = '"' + array[i].statement.replaceAll('"', "'") + '"'
        line += array[i].id + ',' + array[i].statement
        line += '\r\n'

        str += line

      }

      return str
    },
  }
};
</script>

<style scoped></style>
