<template>
  <div>
    <v-card class="d-flex items-center flex-wrap pa-4 my-4">
      <v-checkbox
        v-model="trial"
        label="Trial"
        color="primary"
        class="mr-4"
      ></v-checkbox>
      <v-checkbox
        v-model="isPassage"
        label="Is Passage"
        color="primary"
        class="mr-4"
      ></v-checkbox>
      <v-select
        v-model="category"
        :items="categories"
        item-text="name"
        return-object
        label="Category"
        color="primary"
        @change="subCategories = category.subCategories"
        class="mr-4"
        outlined
        dense
        hide-details
        :loading="categoryLoading"
        :disabled="!categories.length || categoryLoading"
        style="width: 200px"
      ></v-select>
      <v-select
        v-model="subCategory"
        :items="subCategories"
        item-text="name"
        return-object
        label="Sub Category"
        color="primary"
        outlined
        dense
        hide-details
        :disabled="!subCategories.length"
        style="width: 200px"
      ></v-select>
    </v-card>

    <div
      v-if="(payload && payload.question) || payload.length > 0"
      class="preview"
    >
      <div class="preview__questions preview__form">
        <span class="preview__questions__title">Questions</span>
        <div
          :class="[
            'preview__questions__tab',
            item.question === selectedQuestion.question && 'active'
          ]"
          v-for="(item, index) in data"
          :key="index"
          @click="setSelected(item)"
        >
          <span>{{ item.question }}</span>
        </div>
      </div>
      <div class="preview__details preview__form">
        <span class="preview__details__title">Details</span>
        <div v-if="selectedQuestion" class="preview__details__options">
          <div
            v-for="(option, index) in selectedQuestion.choices"
            :key="index"
            :class="[
              'preview__details__options__option',
              selectedQuestion.correctAnswer === index && 'active'
            ]"
          >
            <b>{{ index + 1 }})</b> <span>{{ option }}</span>
          </div>
        </div>
        <v-btn
          color="primary"
          class="preview__details__button"
          min-width="300px"
          @click="proceedQuestion(selectedQuestion.explanation)"
          elevation="0"
          >Proceed</v-btn
        >
      </div>

      <v-dialog
        :width="$vuetify.breakpoint.mdAndUp ? '40%' : '90%'"
        v-model="showQuestionDialog"
      >
        <QuestionForm is-modal @close="closeModal" :initial-data="question" />
      </v-dialog>

      <loading-dialog v-model="loading" message="Generating Description" />
    </div>
  </div>
</template>

<script>
import QuestionForm from '@/components/questions/QuestionForm.vue';
import LoadingDialog from '@/components/LoadingDialog.vue';
import { CategoryService } from '../../services/category-service';

export default {
  name: 'QuestionsPreview',

  components: { QuestionForm, LoadingDialog },

  props: {
    payload: {
      // type will be array or object
      type: [Array, Object],
      required: true
    },
    query: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    categoryService: new CategoryService(),

    data: null,
    selectedQuestion: null,
    config: null,
    showQuestionDialog: false,
    question: null,
    loading: false,

    categories: [],
    categoryLoading: false,

    category: null,
    subCategories: [],
    subCategory: null,
    isPassage: false,
    trial: false
  }),

  watch: {
    payload() {
      this.setData();
    }
  },

  async mounted() {
    await this.loadCategories();
    this.setData();
  },

  methods: {
    closeModal() {
      this.showQuestionDialog = false;
    },

    async loadCategories() {
      this.categoryLoading = true;
      this.category = null;
      this.subCategory = null;
      this.categories = await this.categoryService.fetchAll();
      this.categoryLoading = false;
    },

    async proceedQuestion(explanation) {
      this.question = {
        ...this.selectedQuestion,
        explanation: explanation,
        config: this.config,
        category: this.category,
        subCategory: this.subCategory,
        isPassage: this.isPassage,
        trial: this.trial
      };

      this.showQuestionDialog = true;
    },

    setSelected(question) {
      this.selectedQuestion = question;
    },

    setData() {
      this.config = this.query;

      if (this.payload && this.payload.length > 0) {
        this.data = this.payload;
        this.selectedQuestion = this.payload[0];
      } else {
        this.data = [
          {
            question: this.payload.question,
            choices: this.payload.choices,
            correctAnswer: this.payload.correctAnswer,
            explanation: this.payload.explanation
          }
        ];
        this.selectedQuestion = this.payload;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.preview {
  display: grid;
  max-width: 100%;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 2fr;
  margin-top: 20px;

  &__form {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    width: 100%;
  }

  &__questions {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    gap: 5px;

    &__title {
      font-weight: normal !important;
      font-size: 22px;
      padding-bottom: 10px;
      text-align: center !important;
      font-family: google-sans, sans-serif;
    }

    &__tab {
      width: 100%;
      border-radius: 3px;
      padding: 8px;
      cursor: pointer;

      &.active {
        background-color: #f6f6f6;
      }
    }

    &__tab:hover {
      background-color: #f6f6f6;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 5px;

    &__title {
      font-weight: normal !important;
      font-size: 22px;
      padding-bottom: 10px;
      text-align: center !important;
      font-family: google-sans, sans-serif;
    }

    &__options {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__option {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        border-radius: 3px;
        cursor: pointer;

        &.active {
          background-color: #f6f6f6;
          border: 1px solid #d1d1d1;
        }
      }
    }

    &__button {
      margin: 20px auto 0;
    }
  }
}
</style>
